import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EventEmitter from '@services/EventEmitter';
import {
	ICustomRomExercise,
	ICustonRomPaginated,
	StrapiOmniRomExercises,
} from '@stores/interfaces';
import { message } from 'antd';
import axios from 'axios';
import { stringify } from 'qs';
import { ReduxState } from '..';
import strapi from '@strapi';

export const postRomExercises = createAsyncThunk(
	'postRomExercises',
	async (payload: any): Promise<ICustonRomPaginated> => {
		try {
			const { data } = await axios.post(`/rom/programs`, payload);
			return data;
		} catch (error: any) {
			error?.response?.data.statusCode === 409
				? message.error(error?.response?.data?.message)
				: message.error('Something Went Wrong !');

			throw error;
		}
	},
);

export const getMyLibraryList = createAsyncThunk(
	'getMyLibraryList',
	async ({
		userId,
		search,
		page,
	}: {
		userId: string;
		search: string;
		page: number;
	}): Promise<ICustonRomPaginated> => {
		try {
			const { data } = await axios.get(
				`/rom/library?title=${search ? search : ''}&page=${page}&limit=10`,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const saveOmniRomPhysioterapistVideo = createAsyncThunk(
	'saveOmniRomPhysioterapistVideo',
	async (payload: any): Promise<ICustomRomExercise> => {
		try {
			const { data } = await axios.post('/rom/library', payload, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: progressEvent => {
					EventEmitter.emit(
						'UPLOAD_PROGRESS',
						Math.floor(100 * (progressEvent.loaded / progressEvent?.total!)),
					);
				},
			});
			return data;
		} catch (error: any) {
			error?.response?.data.statusCode === 409
				? message.error(error?.response?.data.message)
				: message.error('Something Went Wrong !');

			throw error;
		}
	},
);

export const getBodyPointsByExerciseId = createAsyncThunk(
	'getBodyPointsByExerciseId',
	async ({
		payload,
		exerciseId,
	}: {
		payload: any;
		exerciseId: string;
	}): Promise<ICustonRomPaginated> => {
		try {
			const { data } = await axios.post(
				`/custom-rom/exercise/library/body-points/${exerciseId}`,
				payload,
			);
			return data;
		} catch (error) {
			throw error;
		}
	},
);

export const fetchSystemTemplateExercises = createAsyncThunk(
	'getFetchSystemAllExercises',
	async (
		{ page, search }: { page: number; search?: string },
		thunkAPI,
	): Promise<StrapiOmniRomExercises[]> => {
		const query = stringify({
			populate: {
				exercises: {
					populate: {
						OmniRomExerciseId: {
							populate: {
								image: {
									fields: 'url',
								},
								video: {
									fields: 'url',
								},
								pointsToCalculateAngle: {
									populate: {
										a: true,
										b: true,
										c: true,
									},
								},
								pointsToValidatePosition: {
									populate: {
										a: true,
										b: true,
										c: true,
									},
								},
								reference: true,
							}
						},
					},
				},
				pagination: {
					page
				},
				filters: {
					$or: [
						{ title: { $contains: search } }
					],
				},
			}
		});

		const { data } = await strapi.get(`/omni-rom-programs?${query}`);
		return data;
	},
);

export const fetchOmniromProgram = createAsyncThunk(
	'fetchOmniromProgram',
	async ({ omniRomProgramId }: { omniRomProgramId: number }, thunkAPI) => {
		const query = stringify({
			populate: {
				exercises: {
					sort: ['order:asc'],
					populate: {
						OmniRomExerciseId: {
							populate: {
								image: { fields: ['url'] },
								video: { fields: ['url'] },
								pointsToCalculateAngle: { populate: { a: true, b: true, c: true } },
								pointsToValidatePosition: { populate: { a: true, b: true, c: true } },
								reference: true,
							},
						},
					},
				},
			},
			filters: { id: omniRomProgramId },
		});
		const { data } = await strapi.get(`/omni-rom-programs/?${query}`);
		return data?.data || [];
	}
);

export const fetchStrapiExercises = createAsyncThunk(
	'fetchStrapiExercises', async() => {
		try {
			const { data } = await strapi.get('/omni-rom-programs');
			return data;
		} catch(error) {
			throw error;
		}
	}
)

export const fetchExercises = createAsyncThunk(
	'getFetchAllExercises',
	async (
		{ page, search }: { page: number; search?: string },
		thunkAPI,
	): Promise<StrapiOmniRomExercises[]> => {
		const { getState } = thunkAPI;

		const query = stringify({
			filters: {
				omniRomExerciseGroups: {
					id: 7,
				},
				$or: [
					{
						name: {
							$contains: search,
						},
					},
				],
			},
			sort: ['order:asc'],
			populate: {
				image: {
					fields: 'url',
				},
				video: {
					fields: 'url',
				},
				pointsToCalculateAngle: {
					populate: {
						a: true,
						b: true,
						c: true,
					},
				},
				pointsToValidatePosition: {
					populate: {
						a: true,
						b: true,
						c: true,
					},
				},
				omniRomExerciseGroups: true,
				reference: true,
			},
			pagination: {
				page: page,
				pageSize: 10,
				withCount: true,
			},
		});

		const { data } = await strapi.get(`/omni-rom-exercises?${query}`);
		return data;
	},
);

const initialState: any = {
	data: [],
	pagination: null,
	romUploadDetails: null,
	romExerciseLibrary: {
		data: null,
		pagination: null,
	},
	carespaceLibraryExercises: null,
	exerciseLoad: true
};

const romTemplates = createSlice({
	name: 'romTemplates',
	initialState,
	reducers: {
		setRomUploadDetails: (state, action) => {
			state.romUploadDetails = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(postRomExercises.fulfilled, (state, action) => {
			state.data = action?.payload?.data;
			state.pagination = action?.payload?.pagination;
		});
		builder.addCase(postRomExercises.rejected, (state, action) => { });
		builder.addCase(getMyLibraryList.fulfilled, (state, action) => {
			state.romExerciseLibrary = action.payload;
		});
		builder.addCase(getMyLibraryList.rejected, (state, action) => { });

		builder.addCase(fetchOmniromProgram.fulfilled, (state, action) => {});
		builder.addCase(fetchOmniromProgram.rejected, (state, action) => { });

		builder.addCase(fetchStrapiExercises.fulfilled, (state, action) => {});
		builder.addCase(fetchStrapiExercises.rejected, (state, action) => { });

		builder.addCase(fetchExercises.fulfilled, (state, action) => {
			state.carespaceLibraryExercises = action.payload;
			state.exerciseLoad = false
		});

		builder.addCase(fetchExercises.pending, (state, action) => {
			state.exerciseLoad = true
		});

		builder.addCase(fetchExercises.rejected, (state, action) => {
			state.exerciseLoad = false
		});

		builder.addCase(getBodyPointsByExerciseId.fulfilled, (state, action) => {
			state.data = action.payload.data;
			state.pagination = action.payload.pagination;
		});
		builder.addCase(getBodyPointsByExerciseId.rejected, (state, action) => { });
		builder.addCase(
			saveOmniRomPhysioterapistVideo.fulfilled,
			(state, action) => { },
		);
		builder.addCase(
			saveOmniRomPhysioterapistVideo.rejected,
			(state, action) => {
				state.completed = false;
			},
		);
	},
});

export const { setRomUploadDetails } = romTemplates.actions;
export default romTemplates.reducer;
